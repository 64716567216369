import { Button } from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
//@ts-ignore
import example from "./example.png"

const SecondPage = () => (
  <Layout>
    <SEO title="Kwitantie voorbeeld pdf" />
    <div className="page content">
      <h1>Kwitanties en de Belastingdienst</h1>
      <div>
       
      
        <p> Over een kwitantie hoeft geen BTW te worden betaald. Het bedrag dient echter wel aangegeven te worden als inkomstenbelasting. Dit kan bij de jaarlijkse aangifte onder 'Inkomsten uit overige werk.' Het onderstaande screenshot geeft aan hoe eenvoudig dit is.
        </p>
   

        <img
          src="belastingdienst.png"
          style={{
            width: "80%",
            textAlign: "center",
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            margin: "3rem auto" 
            
          }}
        />
      </div>

      <Link to="/" style={{ fontFamily: "Lato" }}>
        <Button variantColor="teal" size="lg">
          Aan de slag: maak zelf een kwitantie.{" "}
        </Button>
      </Link>
    </div>
  </Layout>
)

export default SecondPage
